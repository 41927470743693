import React, { useRef, useState } from "react";
import { Form, Alert } from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import "./Login.css";

export default function UpdateProfile() {
  const nameRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const { currentUser, updateDisplayName, updatePassword } = useAuth();
  const history = useHistory();

  function handleSubmit(e) {
    e.preventDefault();
    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match");
    }

    const promises = [];
    setLoading(true);
    setError("");

    if (nameRef.current.value !== currentUser.displayName) {
      promises.push(updateDisplayName(nameRef.current.value));
    }
    if (passwordRef.current.value) {
      promises.push(updatePassword(passwordRef.current.value));
    }

    Promise.all(promises)
      .then(() => {
        let { from } = { from: { pathname: "/" } };
        history.replace(from);
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <div className={`login ${loading ? "div-disabled" : ""}`}>
      <div className="title-container">
        <h2 className="title-text">Update Profile</h2>
        <p className="subtitle-text">Update TERA Account</p>
      </div>
      {error && <Alert variant="danger">{error}</Alert>}
      <Form onSubmit={handleSubmit}>
        <Form.Group id="email">
          <Form.Label>Full Name</Form.Label>
          <Form.Control
            type="text"
            ref={nameRef}
            required
            defaultValue={currentUser.displayName}
          />
        </Form.Group>
        <Form.Group id="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            ref={passwordRef}
            placeholder="Leave blank to keep the same"
          />
        </Form.Group>
        <Form.Group id="password-confirm">
          <Form.Label>Password Confirmation</Form.Label>
          <Form.Control
            type="password"
            ref={passwordConfirmRef}
            placeholder="Leave blank to keep the same"
          />
        </Form.Group>
        <button disabled={loading} className="submit-btn" type="submit">
          Update
        </button>
      </Form>
      <div className="w-100 text-center mt-2">
        <button
          onClick={() => {
            history.goBack();
          }}
          className="btn btn-link"
        >
          Cancel
        </button>
      </div>
    </div>
  );
}
