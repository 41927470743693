import React, { useState } from "react";
import { Form, Alert } from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import "./Login.css";

export default function ForgotPassword() {
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const { sendEmailVerification } = useAuth();

  async function verifyEmail(e) {
    e.preventDefault();

    try {
      setMessage("");
      setError("");
      setLoading(true);
      await sendEmailVerification();
      await setMessage("Check your inbox for further instructions");
    } catch (err) {
      setError(err.message);
    }

    setLoading(false);
  }

  return (
    <div className={`login ${loading ? "div-disabled" : ""}`}>
      <div className="title-container">
        <h2 className="title-text">Verify Email</h2>
        <p className="subtitle-text">Create TERA Account</p>
      </div>
      <div className="alert-container signup">
        {error && <Alert variant="danger">{error}</Alert>}
      </div>
      <div className="alert-container signup">
        {message && <Alert variant="success">{message}</Alert>}
      </div>
      <Form onSubmit={verifyEmail}>
        <button disabled={loading} className="submit-btn" type="submit">
          Send Email Verification
        </button>
      </Form>
    </div>
  );
}
