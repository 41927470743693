import React, { useRef, useState } from "react";
import { Form, Alert } from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import { Link } from "react-router-dom";
import "./Login.css";

export default function ForgotPassword() {
  const emailRef = useRef();
  const { resetPassword } = useAuth();
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setMessage("");
      setError("");
      setLoading(true);
      await resetPassword(emailRef.current.value);
      setMessage("Check your inbox for further instructions");
    } catch {
      setError("Failed to reset password");
    }

    setLoading(false);
  }

  return (
    <div className={`login ${loading ? "div-disabled" : ""}`}>
      <div className="title-container">
        <h2 className="title-text">Password Reset</h2>
        <p className="subtitle-text">Restore TERA Account</p>
      </div>
      <div className="alert-container signup">
        {error && <Alert variant="danger">{error}</Alert>}
      </div>
      <div className="alert-container signup">
        {message && <Alert variant="success">{message}</Alert>}
      </div>
      <Form onSubmit={handleSubmit}>
        <Form.Group id="email">
          <Form.Label>Enter your email to restore password</Form.Label>
          <Form.Control type="email" ref={emailRef} required />
        </Form.Group>
        <button disabled={loading} className="submit-btn" type="submit">
          Reset Password
        </button>
      </Form>
      <div className="w-100 text-center mt-3">
        <Link to="/login">Login</Link>
      </div>
      <div className="w-100 text-center mt-2">
        Need an account? <Link to="/signup">Sign Up</Link>
      </div>
    </div>
  );
}
