import React, { useState, useEffect } from "react";
import { Card, Button, Accordion, Spinner } from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import { db } from "../../firebase";
import "./Dashboard.css";

function convertObjectToArray(data) {
  const array = [];

  for (const [, value] of Object.entries(data)) {
    array.push({ ...value, timestamp: Date.parse(value.timestamp) });
  }

  return array.sort((a, b) => b.timestamp - a.timestamp);
}

function prettyTime(current, previous) {
  var msPerMinute = 60 * 1000;
  var msPerHour = msPerMinute * 60;
  var msPerDay = msPerHour * 24;

  var elapsed = current - previous;

  if (elapsed < msPerMinute) {
    return Math.round(elapsed / 1000) + " seconds ago";
  } else if (elapsed < msPerHour) {
    return Math.round(elapsed / msPerMinute) + " minutes ago";
  } else if (elapsed < msPerDay) {
    return Math.round(elapsed / msPerHour) + " hours ago";
  } else {
    return new Date(parseInt(previous)).toLocaleDateString("en-US", {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  }
}

export default function Attendance() {
  const [loading, setLoading] = useState(false);
  const [attendanceLog, setAttendanceLog] = useState([]);
  const { currentUser } = useAuth();

  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    const attendanceLogRef = db.ref("attendance/");
    attendanceLogRef
      .orderByChild("uid")
      .equalTo(currentUser.uid)
      .limitToLast(10)
      .on("value", (snapshot) => {
        const data = snapshot.val();
        if (data === null) {
          setAttendanceLog([]);
          return;
        }
        const array = convertObjectToArray(data);
        setAttendanceLog(array);
        setLoading(false);
      });
  }, [currentUser]);

  useEffect(() => {
    return () => {
      db.ref("/attendance/" + currentUser.uid).off();
    };
  }, [currentUser]);

  function renderLog(timestamp, dateString, temperature, location) {
    return (
      <Card key={timestamp}>
        <Card.Header className="text-center">
          <Accordion.Toggle as={Button} variant="link" eventKey={timestamp}>
            {dateString}
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey={timestamp}>
          <Card.Body>
            <p>Date: {new Date(timestamp).toLocaleDateString()}</p>
            <p>Time: {new Date(timestamp).toLocaleTimeString()}</p>
            <p>Temperature: {temperature}</p>
            <p>Location: {location}</p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  }

  return (
    <div className="attendance">
      <div className="title-container">
        <h2 className="title-text">Attendance Log</h2>
        <p className="subtitle-text">10 Most recent attendances</p>
      </div>
      <div className="app-container">
        {loading && (
          <div className="text-center">
            <Spinner animation="border" />
          </div>
        )}
        <Accordion>
          {attendanceLog.map((e) => {
            const stringDate = prettyTime(Date.now(), e.timestamp);
            return renderLog(e.timestamp, stringDate, e.temp, e.location);
          })}
        </Accordion>
        <button
          onClick={() => {
            history.goBack();
          }}
          className="btn btn-primary w-100 mt-3"
        >
          Back
        </button>
      </div>
    </div>
  );
}
