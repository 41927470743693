import React, { useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import QRCode from "qrcode";
import "./Dashboard.css";

export default function ViewQR() {
  const { currentUser } = useAuth();
  const [qrData, setQrData] = useState("");

  const history = useHistory();

  useEffect(() => {
    QRCode.toDataURL(currentUser.uid, { scale: 8 }, (err, data) => {
      setQrData(data);
    });
  }, [currentUser]);

  return (
    <div className="qr-full">
      <div className="d-flex justify-content-center align-items-center">
        <img
          width="100%"
          height="100%"
          alt={"QRCode Failed to load. Please refresh"}
          src={qrData}
        />
      </div>
      <button
        onClick={() => {
          history.goBack();
        }}
        className="btn btn-primary w-100 mt-3"
      >
        Go Back
      </button>
    </div>
  );
}
