import React from "react";

const Footer = () => (
  <footer className="footer">
    <p>TERA | © Babega Productions {new Date().getFullYear()}</p>
    <p>
      <a href="https://babega.com">Babega.com</a> |{" "}
      <a href="https://babega.com/contact.php">Contact Me</a>
    </p>
  </footer>
);

export default Footer;
