import React, { useRef, useState } from "react";
import { Form, Alert } from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import { Link, useHistory, useLocation } from "react-router-dom";
import "./Login.css";

export default function Login() {
  const { login } = useAuth();

  const emailRef = useRef();
  const passwordRef = useRef();

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const location = useLocation();

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      await login(emailRef.current.value, passwordRef.current.value);

      let { from } = location.state || { from: { pathname: "/" } };
      history.replace(from);
    } catch (err) {
      setError(err.message);
    }

    setLoading(false);
  }

  // async function handleSignInWithGoogle() {
  //   try {
  //     setError("");
  //     setLoading(true);
  //     await signInWithGoogle();

  //     history.push("/");
  //   } catch (err) {
  //     setError(err.message);
  //   }

  //   setLoading(false);
  // }

  return (
    <div className={`login ${loading ? "div-disabled" : ""}`}>
      <div className="title-container">
        <img
          className="logo-img"
          width="100px"
          height="100px"
          src="/logo192.png"
          alt="Tera Logo"
        />
        <h2 className="title-text">Login</h2>
        <p className="subtitle-text">Access TERA Account</p>
      </div>
      {/* <div className="google-signin-container">
        <button
          disabled={loading}
          className="google-signin-btn"
          onClick={handleSignInWithGoogle}
        >
          <FontAwesomeIcon icon={faGoogle} size="lg" />
        </button>
      </div> */}
      {/* <p className="subtitle-text email-login-text">Or login with email:</p> */}
      <div className="alert-container signup">
        {error && <Alert variant="danger">{error}</Alert>}
      </div>
      <div className="form-container">
        <Form onSubmit={handleSubmit}>
          <Form.Group id="email">
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" ref={emailRef} required />
          </Form.Group>
          <Form.Group id="password">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" ref={passwordRef} required />
          </Form.Group>
          <button disabled={loading} className="submit-btn" type="submit">
            Sign In
          </button>
        </Form>
      </div>
      <div className="w-100 text-center mt-3">
        <Link to="/forgot-password">Forgot Password?</Link>
      </div>
      <div className="w-100 text-center mt-2">
        Need an account? <Link to="/signup">Sign Up</Link>
      </div>
    </div>
  );
}
