import React, { useRef, useState } from "react";
import { Form, Alert } from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import { Link, useHistory, useLocation } from "react-router-dom";
import "./Login.css";

export default function Signup() {
  const nameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const { signup } = useAuth();

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const location = useLocation();

  async function handleSubmit(e) {
    e.preventDefault();

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match");
    }

    try {
      setError("");
      setLoading(true);
      await signup(
        emailRef.current.value,
        passwordRef.current.value,
        nameRef.current.value
      );

      let { from } = location.state || { from: { pathname: "/" } };
      history.replace(from);
    } catch (err) {
      setError(err.message);
    }

    setLoading(false);
  }

  return (
    <div className={`login ${loading ? "div-disabled" : ""}`}>
      <div className="title-container">
        <img
          className="logo-img"
          width="100px"
          height="100px"
          src="/logo192.png"
          alt="Tera Logo"
        />
        <h2 className="title-text">Sign Up</h2>
        <p className="subtitle-text">Create TERA Account</p>
      </div>
      <div className="alert-container signup">
        {error && <Alert variant="danger">{error}</Alert>}
      </div>
      <div className="form-container">
        <Form onSubmit={handleSubmit}>
          <Form.Group id="name">
            <Form.Label>Full Name</Form.Label>
            <Form.Control type="text" ref={nameRef} required />
          </Form.Group>
          <Form.Group id="email">
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" ref={emailRef} required />
          </Form.Group>
          <Form.Group id="password">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" ref={passwordRef} required />
          </Form.Group>
          <Form.Group id="password-confirm">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control type="password" ref={passwordConfirmRef} required />
          </Form.Group>
          <button disabled={loading} className="submit-btn" type="submit">
            Sign Up
          </button>
        </Form>
      </div>
      <div className="w-100 text-center mt-3">
        Already have an account? <Link to="/login">Log In</Link>
      </div>
    </div>
  );
}
