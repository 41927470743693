import React, { useState, useEffect } from "react";
import { Button, Alert } from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import { Link, useHistory, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import QRCode from "qrcode";
import "./Dashboard.css";

export default function Dashboard() {
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [qrData, setQrData] = useState("");
  const { currentUser, sendEmailVerification, logout } = useAuth();

  const history = useHistory();
  const location = useLocation();

  async function handleLogout() {
    setError("");

    try {
      await logout();

      let { from } = location.state || { from: { pathname: "/" } };
      history.replace(from);
    } catch {
      setError("Failed to log out");
    }
  }

  async function verifyEmail(e) {
    e.preventDefault();

    try {
      setMessage("");
      setError("");
      await sendEmailVerification();
      await setMessage("Check your inbox for further instructions");
    } catch (err) {
      setError(err.message);
    }
  }

  useEffect(() => {
    QRCode.toDataURL(currentUser.uid, { scale: 8 }, (err, data) => {
      setQrData(data);
    });
    history.listen(() => {
      if (window.swUpdateReady) {
        window.swUpdateReady = false;
        window.stop();
        window.location.reload();
      }
    });
  }, [currentUser, history]);

  return (
    <div className="dashboard">
      <div className="title-container">
        <h2 className="title-text">TERA</h2>
      </div>
      <div className="app-container">
        {error && <Alert variant="danger">{error}</Alert>}
        {message && <Alert variant="success">{message}</Alert>}
        <div className="widget-container">
          <div className="row widget-header">
            <p className="widget-title-text">User Info</p>
            <Link to="/profile" className="fa-link">
              Edit <FontAwesomeIcon icon={faEdit} />
            </Link>
          </div>
          <div className="row widget-content">
            <div className="row col-12">
              <p className="widget-text">
                Name: {currentUser.displayName || "Not Set"}
              </p>
            </div>
            <div className="row col-12">
              <p className="widget-text">Email: {currentUser.email}</p>
            </div>
          </div>
        </div>
        <div className="widget-container">
          <div className="row widget-header">
            <p className="widget-title-text">My QR Code</p>
          </div>
          <div className="row widget-content qr-widget">
            {currentUser.emailVerified ? (
              <>
                <Link className="preview-qr" to="/view-qr">
                  <img
                    src={qrData}
                    className="qr-img"
                    alt="My QR Code"
                    width="100%"
                    height="100%"
                  />
                </Link>
                <p className="qr-img-caption">Tap to expand</p>
              </>
            ) : (
              <button className="submit-btn verify-btn" onClick={verifyEmail}>
                Verify email to view QR
              </button>
            )}
          </div>
        </div>
        <div className="widget-container">
          <div className="row widget-header">
            <p className="widget-title-text">Recent Activities</p>
          </div>
          <div className="row widget-content">
            <Link to="/attendance" className="view-more-link">
              View More
            </Link>
          </div>
        </div>
      </div>
      <div className="w-100 text-center mt-2">
        <Button variant="danger" onClick={handleLogout}>
          Log Out
        </Button>
      </div>
    </div>
  );
}
