import React, { useState, useEffect } from "react";
import Fade from "react-reveal/Fade";
import { Container } from "react-bootstrap";
import { AuthProvider } from "../contexts/AuthContext";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Dashboard from "./Screens/Dashboard";
import Login from "./Auth/Login";
import Signup from "./Auth/Signup";
import ForgotPassword from "./Auth/ForgotPassword";
import VerifyEmail from "./Auth/VerifyEmail";
import PrivateRoute from "./PrivateRoute";
import UpdateProfile from "./Auth/UpdateProfile";
import ViewQR from "./Screens/ViewQR";
import Attendance from "./Screens/Attendance";
import Footer from "./Footer";
let deferredPrompt;

function App() {
  const [installBannerVisible, setInstallBannerVisible] = useState(false);

  async function handleInstall() {
    setInstallBannerVisible(false);
    deferredPrompt.prompt();
    deferredPrompt = null;
  }

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", function (e) {
      e.preventDefault();
      setInstallBannerVisible(true);
      deferredPrompt = e;
    });
    window.addEventListener("appinstalled", () => {
      setInstallBannerVisible(false);
      deferredPrompt = null;
    });
  }, []);

  return (
    <>
      {installBannerVisible && (
        <Fade top>
          <div className="install-banner">
            <p className="install-text">Install App</p>
            <button className="install-btn" onClick={handleInstall}>
              Install
            </button>
          </div>
        </Fade>
      )}
      <Container
        className="d-flex justify-content-center"
        style={{ minHeight: "100vh", paddingBottom: "24px" }}
      >
        <div
          className="w-100"
          style={{ maxWidth: "400px", position: "relative" }}
        >
          <Router>
            <AuthProvider>
              <Switch>
                <PrivateRoute exact path="/" component={Dashboard} />
                <PrivateRoute exact path="/profile" component={UpdateProfile} />
                <PrivateRoute exact path="/view-qr" component={ViewQR} />
                <PrivateRoute exact path="/attendance" component={Attendance} />
                <PrivateRoute
                  exact
                  path="/verify-email"
                  component={VerifyEmail}
                />
                <Route exact path="/signup" component={Signup} />
                <Route exact path="/login" component={Login} />
                <Route
                  exact
                  path="/forgot-password"
                  component={ForgotPassword}
                />
              </Switch>
            </AuthProvider>
          </Router>
        </div>
      </Container>
      <Footer />
    </>
  );
}

export default App;
