import React, { useContext, useState, useEffect } from "react";
import { auth } from "../firebase";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  // const googleProvider = new firebase.auth.GoogleAuthProvider();

  // function signInWithGoogle() {
  //   return auth.signInWithPopup(googleProvider);
  // }

  async function signup(email, password, name) {
    await auth.createUserWithEmailAndPassword(email, password);
    await auth.currentUser.updateProfile({ displayName: name });
  }

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password);
  }

  function logout() {
    return auth.signOut();
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }

  function updateDisplayName(name) {
    currentUser.updateProfile({
      displayName: name,
    });
  }

  // function updateEmail(email) {
  //   return currentUser.updateEmail(email);
  // }

  function sendEmailVerification() {
    return currentUser.sendEmailVerification();
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password);
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    login,
    signup,
    logout,
    sendEmailVerification,
    resetPassword,
    updatePassword,
    updateDisplayName,
    // signInWithGoogle,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
